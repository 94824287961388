import { AnimatedSprite, Sprite, Text, Texture, Ticker } from "pixi.js";
import { DESTROY_Y_LIMIT, GRAVITY, STANDARD_FPS, SYMBOL_GAP } from "../../resources/constants";
import { ISymbolEvents } from "./types";
import Reel from "../reel";

export default class Symbol {
  border?: Sprite;
  falling = true;
  fallAnimationDone = false;
  destroying = false;
  exploded = false;
  fallAnimationTime = 0;
  fallAnimationStarted = false;
  velocityY = 0;
  gravity = GRAVITY;
  wind = 0;
  fallAnimationAmount = SYMBOL_GAP / 2;
  fallAnimationDuration = 100;
  multiplier = 0;
  constructor(
    private reel: Reel,
    public symbolType: number,
    private sprite: Sprite,
    public position: { start: { x: number; y: number }; end: { y: number } },
    private events: ISymbolEvents,
    public index: number
  ) {
    this.sprite.x = this.position.start.x;
    this.sprite.y = this.position.start.y;
    if (symbolType > 100000) {
      this.multiplier = symbolType - 100000;
    }
    this.fallDown(position.end.y);
    if (this.multiplier > 0) {
      // add multiplier text
      const text = new Text();
      text.text = `x${this.multiplier}`;
      text.style = {
        fontFamily: "Arial",
        fontSize: 100,
        fill: 0xffffff,
        align: "center",
        fontWeight: "bold",
      };

      const textContainer = new Sprite();
      textContainer.addChild(text);
      this.sprite.addChild(textContainer);
      // text.anchor.set(0.5);
      textContainer.x = this.sprite.width / 2;
      textContainer.y = this.sprite.height / 2;
    }
  }

  destroy() {
    this.destroying = true;
  }

  select() {
    this.border = new Sprite(Texture.from("symbolSelect"));
    this.reel.container.addChild(this.border);
    this.border.x = this.sprite.x - SYMBOL_GAP / 3;
    this.border.y = this.sprite.y - SYMBOL_GAP / 3;
    this.border.width = this.sprite.width + SYMBOL_GAP / 1.5;
    this.border.height = this.sprite.height + SYMBOL_GAP / 1.5;
  }

  deselect() {
    if (this.border) {
      this.reel.container.removeChild(this.border);
      this.border.destroy();
      this.border = undefined;
    }
  }

  shake = (magnitude: number, duration: number) => {
    const originalX = this.sprite.x;
    const originalY = this.sprite.y;
    let elapsed = 0;
    const shakeUpdate = () => {
      const elapsedFraction = elapsed / duration;
      const damping = 1 - elapsedFraction;

      const offsetX = Math.random() * magnitude * 2 - magnitude;
      const offsetY = Math.random() * magnitude * 2 - magnitude;

      this.sprite.x = originalX + offsetX * damping;
      this.sprite.y = originalY + offsetY * damping;

      elapsed += this.reel.game.app.ticker.elapsedMS;

      if (elapsed < duration) {
        requestAnimationFrame(shakeUpdate);
      } else {
        this.sprite.x = originalX;
        this.sprite.y = originalY;
      }
    };
    shakeUpdate();
  };

  // Update Functions
  update(ticker: Ticker) {
    if (this.exploded) {
    } else if (this.falling) {
      if (this.sprite.y < this.position.end.y) {
        this.updateFalling(ticker);
      } else {
        this.updateShaking(ticker);
      }
      if (this.sprite.y < this.position.end.y + 100) {
        if (!this.fallAnimationStarted) {
          this.fallAnimationStarted = true;
          if (this.index === 0) this.reel.game.soundManager.playLandingMusic();
        }
      }
    } else if (this.destroying) {
      if (this.sprite.y < DESTROY_Y_LIMIT) {
        this.updateFalling(ticker);
      }
    }

    this.checkDestroyingFinished();
    this.checkFallingFinished();
  }
  updateShaking(ticker: Ticker) {
    const fpsRatio = ticker.FPS / STANDARD_FPS;

    if (this.fallAnimationTime < this.fallAnimationDuration) {
      if (this.fallAnimationTime < this.fallAnimationDuration / 2) {
        const diff = this.fallAnimationDuration / 2 - this.fallAnimationTime;
        this.sprite.y += ((diff / this.fallAnimationDuration) * this.fallAnimationAmount) / fpsRatio;
      } else {
        const diff = -1 * (this.fallAnimationDuration / 2 - this.fallAnimationTime);
        this.sprite.y -= ((diff / this.fallAnimationDuration) * this.fallAnimationAmount) / fpsRatio;
      }
      this.fallAnimationTime += ticker.deltaMS;
    } else {
      this.fallAnimationDone = true;
      this.sprite.x = this.position.start.x;
      this.sprite.y = this.position.end.y;
    }
  }
  updateFalling(ticker: Ticker) {
    const fpsRatio = ticker.FPS / STANDARD_FPS;

    this.velocityY += this.gravity / fpsRatio;
    this.sprite.y += this.velocityY;
    this.sprite.x += this.wind / fpsRatio;
  }

  // Check Events
  checkDestroyingFinished() {
    if (this.destroying && this.sprite.y > DESTROY_Y_LIMIT) {
      this.destroying = false;
      this.events.onDestroy();
    }
  }

  checkFallingFinished() {
    if (this.falling && this.fallAnimationDone && (this.sprite.destroyed || this.sprite?.y >= this.position.end.y)) {
      this.falling = false;

      this.events.onFallComplete();
    }
  }

  fallDown(newY: number, clearVelocity = true) {
    this.position.end.y = newY;
    this.falling = true;
    this.fallAnimationDone = false;
    this.fallAnimationTime = 0;
    this.fallAnimationStarted = false;
    if (clearVelocity) {
      this.velocityY = 0;
    }
  }

  explode() {
    const explosionTextures = [];
    let i;

    for (i = 0; i < 26; i++) {
      const texture = Texture.from(`Explosion_Sequence_A ${i + 1}.png`);

      explosionTextures.push(texture);
    }
    const explosion = new AnimatedSprite(explosionTextures);
    explosion.currentFrame = 0;
    explosion.x = this.sprite.x + this.sprite.width / 2;
    explosion.y = this.sprite.y + this.sprite.height / 2;
    explosion.anchor.set(0.5);
    explosion.rotation = Math.random() * Math.PI;
    explosion.scale.set(0.5);
    explosion.gotoAndPlay(0);
    explosion.animationSpeed = 0.3;
    explosion.onFrameChange = (f) => {
      if (f === explosionTextures.length - 1) {
        this.events.onExplodeComplete();
        explosion?.destroy();
        this.reel.container.removeChild(explosion as AnimatedSprite);
      }
    };
    this.exploded = true;
    this.reel.container.addChild(explosion);
    this.reel.container.removeChild(this.sprite);
  }
}
