import { InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Dropdown, Flex, theme } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Game } from "../../lib/games/game";
import styles from "./index.module.css";
import { IGameConfig } from "../../lib/games/types";
import InfoScreen from "../infoScreen";

const GameControls: React.FC<{ game: Game }> = ({ game }) => {
  const [soundEnabled, setSoundEnabled] = useState(game.soundEnabled);
  const [stake, setStake] = useState(game.stake);
  const [balance, setBalance] = useState(game.balance);
  const [isRunning, setIsRunning] = useState(game.isRunning);
  const [stakeOptions, setStakeOptions] = useState<number[]>([]);
  const [infoModalOpened, setInfoModalOpened] = useState(false);

  useEffect(() => {
    const stakeListener = (stake: number) => {
      setStake(stake);
    };
    game.addStakeChangeListener(stakeListener);

    const configListener = (config: IGameConfig) => {
      setStakeOptions(config.limits.legalBets);
    };
    game.addConfigChangeListener(configListener);

    const balanceListener = (balance: number) => {
      setBalance(balance);
    };
    game.addBalanceChangeListener(balanceListener);

    const soundListener = (enabled: boolean) => {
      setSoundEnabled(enabled);
    };
    game.addSoundChangeListener(soundListener);

    const gameStateListener = (running: boolean) => {
      setIsRunning(running);
    };
    game.addGameStateChangeListener(gameStateListener);

    return () => {
      game.removeStakeChangeListener(stakeListener);
      game.removeConfigChangeListener(configListener);
      game.removeBalanceChangeListener(balanceListener);
      game.removeSoundChangeListener(soundListener);
      game.removeGameStateChangeListener(gameStateListener);
    };
  }, [game]);

  const handleIncreaseStake = () => {
    game.onClick();
    const currentIndex = stakeOptions.indexOf(stake) || 0;
    game.stake = stakeOptions[(currentIndex + 1) % stakeOptions.length];
  };

  const handleDecreaseStake = () => {
    game.onClick();
    const currentIndex = stakeOptions.indexOf(stake) || 0;
    game.stake = stakeOptions[(currentIndex - 1 + stakeOptions.length) % stakeOptions.length];
  };

  const renderStakeOption = (option: number) => {
    return (
      <Button
        style={{
          width: 110,
          borderColor: option === stake ? "#D29737" : undefined,
          fontSize: 24,
          fontWeight: 800,
        }}
        onClick={() => {
          game.onClick();
          game.stake = option;
        }}
        key={option}
      >
        {option}
      </Button>
    );
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#D29737",
        },
      }}
    >
      <div className={styles.container}>
        <div className={styles.side_group_container}>
          <Button className={styles.bonus_button} onClick={game.onClick}>
            BUY BONUS
          </Button>
          <Flex justify="space-between" className={styles.side_icons_container}>
            <Button
              disabled={isRunning}
              type="link"
              icon={<MinusCircleOutlined className={styles.small_icon} />}
              ghost
              onClick={handleDecreaseStake}
            />
            <Dropdown
              disabled={isRunning}
              menu={{ items: [] }}
              placement="bottom"
              trigger={["click"]}
              dropdownRender={(menu) => (
                <div className={styles.stake_dropdown}>{stakeOptions.map((option) => renderStakeOption(option))}</div>
              )}
            >
              <Button className={styles.stake} ghost type="text" size="small" onClick={game.onClick}>
                {stake}
              </Button>
            </Dropdown>

            <Button
              disabled={isRunning}
              type="link"
              icon={<PlusCircleOutlined className={styles.small_icon} />}
              ghost
              onClick={handleIncreaseStake}
            />
          </Flex>
        </div>

        <Button
          disabled={isRunning || balance < stake}
          style={{
            width: 130,
            height: 130,
          }}
          ghost
          type="link"
          icon={<ReloadOutlined className={styles.run_icon} />}
          onClick={() => {
            game.runReels();
          }}
        />
        <div className={styles.side_group_container}>
          <Button className={styles.bonus_button} onClick={game.onClick}>
            GOLDEN BET
          </Button>
          <Flex justify="space-between" className={styles.side_icons_container}>
            <Button
              ghost
              type="link"
              icon={<i className={"fa fa-refresh " + styles.circled_custom_icon} aria-hidden="true" />}
            />
            <Button
              ghost
              type="link"
              icon={
                soundEnabled ? (
                  <i className={"fa fa-volume-up " + styles.circled_custom_icon} aria-hidden="true" />
                ) : (
                  <i className={"fa fa-volume-off " + styles.circled_custom_icon} aria-hidden="true" />
                )
              }
              onClick={() => (game.soundEnabled = !game.soundEnabled)}
            />
            <Button
              ghost
              type="link"
              icon={<InfoCircleOutlined className={styles.small_icon} />}
              onClick={() => {
                game.onClick();
                setInfoModalOpened(true);
              }}
            />
          </Flex>
        </div>

        <InfoScreen game={game} visible={infoModalOpened} onClose={() => setInfoModalOpened(false)} />
      </div>
    </ConfigProvider>
  );
};

export default memo(GameControls);
