// SymbolHelper.ts
export class SymbolHelper {
  private reelSize: number;
  private symbolType: number;
  private noOfReels: number;
  private multiplierBase = 100000;
  private possibleMultipliers = [2, 5, 10, 50, 100, 250, 500];

  constructor(symbolType: number, reelSize: number, noOfReels: number) {
    this.reelSize = reelSize;
    this.symbolType = symbolType;
    this.noOfReels = noOfReels;
  }

  public generateSymbols(): number[][] {
    switch (this.symbolType) {
      case 1:
        return this.randomSymbols();
      case 2:
        return this.OneSymbolWin();
      case 3:
        return this.TwoSymbolWin();
      case 4:
        return this.OneMultiplier();
      case 5:
        return this.ScatterWin();
      default:
        return this.randomSymbols();
    }
  }

  private OneSymbolWin(): number[][] {
    const specificInts = [
      {
        int: this.getRandomNumber(1, 9),
        occurrence: this.getRandomNumber(8, 12),
      },
    ];

    return this.generateJaggedArrayWithSpecificInts(specificInts);
  }

  private TwoSymbolWin(): number[][] {
    const specificInts = [
      {
        int: this.getRandomNumber(1, 9),
        occurrence: this.getRandomNumber(8, 12),
      },
      {
        int: this.getRandomNumber(1, 9),
        occurrence: this.getRandomNumber(8, 12),
      },
    ];

    return this.generateJaggedArrayWithSpecificInts(specificInts);
  }

  private ScatterWin(): number[][] {
    const specificInts = [{ int: 10, occurrence: this.getRandomNumber(4, 6) }];

    return this.generateJaggedArrayWithSpecificInts(specificInts);
  }

  private OneMultiplier(): number[][] {
    const winningindex = this.getRandomNumber(0, 6);
    const winningSymbol =
      this.multiplierBase + this.possibleMultipliers[winningindex];
    const winningSymbolOccurrance = 1;

    const specificInts = [
      { int: winningSymbol, occurrence: winningSymbolOccurrance },
      {
        int: this.getRandomNumber(1, 9),
        occurrence: this.getRandomNumber(8, 12),
      },
    ];

    return this.generateJaggedArrayWithSpecificInts(specificInts);
  }

  private randomSymbols(): number[][] {
    const result: number[][] = [];
    for (let i = 0; i < this.noOfReels; i++) {
      const row: number[] = [];
      for (let j = 0; j < this.reelSize; j++) {
        row.push(this.getRandomNumber()); // Replace with your logic
      }
      result.push(row);
    }
    return result;
  }

  private getRandomNumber(min: number = 1, max: number = 10): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  private generateJaggedArrayWithSpecificInts(
    specificInts: { int: number; occurrence: number }[]
  ): number[][] {
    const rows = this.noOfReels; // Number of rows in the jagged array
    const cols = this.reelSize; // Number of elements in each sub-array
    const jaggedArray: number[][] = [];

    // Total number of elements in the jagged array
    const totalElements = rows * cols;

    // Calculate total occurrences
    const totalOccurrences = specificInts.reduce(
      (sum, item) => sum + item.occurrence,
      0
    );

    if (totalOccurrences > totalElements) {
      throw new Error(
        "The total occurrences exceed the total number of elements in the array."
      );
    }

    // Flatten the jagged array structure to insert specificInts
    const flatArray: number[] = new Array(totalElements).fill(null);

    // Helper function to check if a number is a specificInt
    const isSpecificInt = (
      specificInts: { int: number }[],
      randomNumber: number
    ): boolean => {
      return specificInts.some((item) => item.int === randomNumber);
    };
    // Helper function to find a valid random number
    const findValidRandomNumber = (specificInts: { int: number }[]): number => {
      let randomNumber: number;
      do {
        randomNumber = this.getRandomNumber();
      } while (isSpecificInt(specificInts, randomNumber));
      return randomNumber;
    };

    // Insert each specific integer into the array based on its occurrence
    for (const { int, occurrence } of specificInts) {
      let remainingOccurrences = occurrence;
      while (remainingOccurrences > 0) {
        const randomIndex = Math.floor(Math.random() * totalElements);
        if (flatArray[randomIndex] === null) {
          flatArray[randomIndex] = int;
          remainingOccurrences--;
        }
      }
    }

    // Fill the rest of the array with random numbers between 1 and 11, excluding all specificInts
    for (let i = 0; i < totalElements; i++) {
      if (flatArray[i] === null) {
        flatArray[i] = findValidRandomNumber(specificInts);
      }
    }

    // Convert flat array back into a jagged array
    for (let i = 0; i < rows; i++) {
      const subArray = flatArray.slice(i * cols, i * cols + cols);
      jaggedArray.push(subArray);
    }

    return jaggedArray;
  }
}
