import { IGameOutcome } from "./dede/service/types";
import {
  BalanceChangeListener,
  ClickListener,
  DeviceType,
  DeviceTypeChangeListener,
  ExplodeListener,
  GameConfigChangeListener,
  GameStateChangeListener,
  IGameConfig,
  SelectListener,
  SoundChangeListener,
  SpinListener,
  StakeChangeListener,
  TumbleListener,
} from "./types";

export class Game {
  id: string = "";
  private _spinListeners: SpinListener[] = [];
  private _selectListeners: SelectListener[] = [];
  private _explodeListeners: ExplodeListener[] = [];
  private _clickListeners: ClickListener[] = [];

  private _soundEnabled: boolean = false;
  private _soundChangeListeners: SoundChangeListener[] = [];

  private _balance: number = 0;
  private _balanceChangeListeners: BalanceChangeListener[] = [];

  private _stake: number = 0;
  private _stakeChangeListeners: StakeChangeListener[] = [];

  private _deviceType: DeviceType = "desktop";
  private _deviceTypeChangeListeners: DeviceTypeChangeListener[] = [];

  private _isRunning: boolean = false;
  private _gameStateChangeListeners: GameStateChangeListener[] = [];

  private _tumbleListeners: TumbleListener[] = [];

  private _config: IGameConfig = {
    payTable: {
      symbolPayouts: [],
    },
    limits: {
      defaultBet: 0,
      legalBets: [],
      autoBets: [],
    },
  };
  private _gameConfigChangeListeners: GameConfigChangeListener[] = [];

  index = 0;

  constructor() {
    if (this.constructor === Game) {
      throw new Error("Cannot instantiate an abstract class.");
    }

    window.addEventListener("resize", () => {
      const newDeviceType = this.getDeviceType();
      if (newDeviceType !== this._deviceType) {
        this._deviceType = newDeviceType;
        this._triggerDeviceTypeChangeEvent(newDeviceType);
      }
    });
    setTimeout(() => {
      this._triggerDeviceTypeChangeEvent(this.getDeviceType());
    }, 500);
    this.id = Math.random().toString(36).substring(2, 9);
  }

  // Abstract method
  runReels() {
    throw new Error("Abstract method 'runReels' must be implemented.");
  }

  public get soundEnabled(): boolean {
    return this._soundEnabled;
  }

  public set soundEnabled(value: boolean) {
    this._soundEnabled = value;
    this._triggerSoundChangeEvent(value);
  }

  public get balance(): number {
    return this._balance;
  }

  public set balance(value: number) {
    this._balance = value;
    this._triggerBalanceChangeEvent(value);
  }

  public get stake(): number {
    return this._stake;
  }

  public set stake(value: number) {
    this._stake = value;
    this._triggerStakeChangeEvent(value);
  }

  public get isRunning(): boolean {
    return this._isRunning;
  }

  protected set isRunning(value: boolean) {
    this._isRunning = value;
    this._triggerGameStateChangeEvent(value);
  }

  public get config(): IGameConfig {
    return this._config;
  }

  public set config(value: IGameConfig) {
    if (JSON.stringify(this._config) === JSON.stringify(value)) return;
    this._config = value;
    this._triggerConfigChangeEvent(value);
  }

  //spin listeners
  public addSpinListener(listener: SpinListener): void {
    this._spinListeners.push(listener);
  }
  public removeSpinListener(listener: SpinListener): void {
    this._spinListeners = this._spinListeners.filter((l) => l !== listener);
  }
  private _triggerSpinEvent(): void {
    for (const listener of this._spinListeners) {
      listener();
    }
  }

  //spin listeners
  public addClickListener(listener: ClickListener): void {
    this._clickListeners.push(listener);
  }
  public removeClickListener(listener: ClickListener): void {
    this._clickListeners = this._clickListeners.filter((l) => l !== listener);
  }
  private _triggerClickEvent(): void {
    for (const listener of this._clickListeners) {
      listener();
    }
  }

  //select listeners
  public addSelectListener(listener: SelectListener): void {
    this._selectListeners.push(listener);
  }
  public removeSelectListener(listener: SelectListener): void {
    this._selectListeners = this._selectListeners.filter((l) => l !== listener);
  }
  private _triggerSelectEvent(): void {
    for (const listener of this._selectListeners) {
      listener();
    }
  }

  //explode listeners
  public addExplodeListener(listener: ExplodeListener): void {
    this._explodeListeners.push(listener);
  }
  public removeExplodeListener(listener: ExplodeListener): void {
    this._explodeListeners = this._explodeListeners.filter((l) => l !== listener);
  }
  private _triggerExplodeEvent(): void {
    for (const listener of this._explodeListeners) {
      listener();
    }
  }

  //sound change listeners
  public addSoundChangeListener(listener: SoundChangeListener): void {
    this._soundChangeListeners.push(listener);
  }

  public removeSoundChangeListener(listener: SoundChangeListener): void {
    this._soundChangeListeners = this._soundChangeListeners.filter((l) => l !== listener);
  }

  private _triggerSoundChangeEvent(enabled: boolean): void {
    for (const listener of this._soundChangeListeners) {
      listener(enabled);
    }
  }

  //balance change listeners
  public addBalanceChangeListener(listener: BalanceChangeListener): void {
    this._balanceChangeListeners.push(listener);
  }

  public removeBalanceChangeListener(listener: BalanceChangeListener): void {
    this._balanceChangeListeners = this._balanceChangeListeners.filter((l) => l !== listener);
  }

  private _triggerBalanceChangeEvent(newBalance: number): void {
    for (const listener of this._balanceChangeListeners) {
      listener(newBalance);
    }
  }

  //stake change listeners
  public addStakeChangeListener(listener: StakeChangeListener): void {
    this._stakeChangeListeners.push(listener);
  }

  public removeStakeChangeListener(listener: StakeChangeListener): void {
    this._stakeChangeListeners = this._stakeChangeListeners.filter((l) => l !== listener);
  }

  private _triggerStakeChangeEvent(newStake: number): void {
    for (const listener of this._stakeChangeListeners) {
      listener(newStake);
    }
  }

  //deviceType change listeners
  public addDeviceTypeChangeListener(listener: DeviceTypeChangeListener): void {
    this._deviceTypeChangeListeners.push(listener);
  }

  public removeDeviceTypeChangeListener(listener: DeviceTypeChangeListener): void {
    this._deviceTypeChangeListeners = this._deviceTypeChangeListeners.filter((l) => l !== listener);
  }

  private _triggerDeviceTypeChangeEvent(newDeviceType: DeviceType): void {
    for (const listener of this._deviceTypeChangeListeners) {
      listener(newDeviceType);
    }
  }

  //game state change listeners

  public addGameStateChangeListener(listener: GameStateChangeListener): void {
    this._gameStateChangeListeners.push(listener);
  }

  public removeGameStateChangeListener(listener: GameStateChangeListener): void {
    this._gameStateChangeListeners = this._gameStateChangeListeners.filter((l) => l !== listener);
  }

  private _triggerGameStateChangeEvent(running: boolean): void {
    for (const listener of this._gameStateChangeListeners) {
      listener(running);
    }
  }

  //config change listeners

  public addConfigChangeListener(listener: GameConfigChangeListener): void {
    this._gameConfigChangeListeners.push(listener);
  }

  public removeConfigChangeListener(listener: GameConfigChangeListener): void {
    this._gameConfigChangeListeners = this._gameConfigChangeListeners.filter((l) => l !== listener);
  }

  private _triggerConfigChangeEvent(config: IGameConfig): void {
    for (const listener of this._gameConfigChangeListeners) {
      listener(config);
    }
  }
  //tumble listeners

  public addTumbleListener(listener: TumbleListener): void {
    this._tumbleListeners.push(listener);
  }

  public removeTumbleListener(listener: TumbleListener): void {
    this._tumbleListeners = this._tumbleListeners.filter((l) => l !== listener);
  }

  private _triggerTumbleEvent(outcome: IGameOutcome): void {
    for (const listener of this._tumbleListeners) {
      listener(outcome);
    }
  }

  //methods

  tumble(outcome: IGameOutcome) {
    this._triggerTumbleEvent(outcome);
  }

  onMountDone() {
    this._triggerDeviceTypeChangeEvent(this.getDeviceType());
  }

  onExplode = () => {
    this._triggerExplodeEvent();
  };

  onSelect = () => {
    this._triggerSelectEvent();
  };

  onClick = () => {
    this._triggerClickEvent();
  };

  onSpin = () => {
    this._triggerSpinEvent();
  };

  getDeviceType(): DeviceType {
    if (window.innerWidth <= 768) {
      return "mobile";
    } else if (window.innerWidth <= 1024) {
      return "tablet";
    } else {
      return "desktop";
    }
  }
}
