import { Dede } from "../..";
import { DeviceType } from "../../../types";
import { SpineAnimator } from "../../../../../components/spineAnimator/SpineAnimator";

export class MainCharacter {
  private spineAnimator?: SpineAnimator;
  private runTimeout?: NodeJS.Timeout;
  constructor(private game: Dede) {}

  async mount() {
    this.spineAnimator = new SpineAnimator("spineboyData", "spineboyAtlas");
    this.spineAnimator.mount("idle", "portal");

    this.game.addTumbleListener(this.handleTumble);
    this.spineAnimator.spine!.state.data.defaultMix = 0.2;


    this.spineAnimator.spine!.eventMode = "static";
    this.game.app.stage.addChild(this.spineAnimator.spine!);
    this.game.addDeviceTypeChangeListener(this.handleDeviceTypeChange);
  }

  handleDeviceTypeChange = (deviceType: DeviceType) => {
    if (!this.spineAnimator!.spine) return;
    if (deviceType === "mobile") {
      this.spineAnimator!.spine!.scale = 0.5;
      this.spineAnimator!.spine!.x = (window.innerWidth - this.spineAnimator!.spine!.width) / 2 + 80;
      this.spineAnimator!.spine!.y = 250;
    } else {
      this.spineAnimator!.spine!.scale = 1;
      this.spineAnimator!.spine!.x = window.innerWidth - this.spineAnimator!.spine!.width + 80;
      this.spineAnimator!.spine!.y = window.innerHeight - this.spineAnimator!.spine!.height - 100;
    }
  };

  handleTumble = () => {
    const runDuration = 4000;
    if (!this.runTimeout && this.spineAnimator!.spine!) {
      const runAnimation = this.spineAnimator!.spine!.state.setAnimation(0, "run", true);
      runAnimation.timeScale = 1.5;
    }
    clearTimeout(this.runTimeout);
    this.runTimeout = setTimeout(() => {
      this.spineAnimator!.spine!.state.setAnimation(0, "idle", true);
      this.runTimeout = undefined;
    }, runDuration);
  };
}
