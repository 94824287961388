export const STANDARD_FPS = 20;
export const SHOW_BORDERS = false;
export const SYMBOL_HEIGHT_DESKTOP = 80;
export const SYMBOL_WIDTH_DESKTOP = 90;
export const DESTROY_Y_LIMIT = 2000;
export const SELECTION_TIME = 1000; // ms
export const GRAVITY = 2.5;
export const WIND = 0;
export const SYMBOL_TYPES = 4;
export const SYMBOLS_PER_REEL = 5;
export const REELS_COUNT = 6;
export const REELS_MARGIN_LEFT_DESKTOP = 100;
export const REELS_MARGIN_LEFT_MOBILE = 40;

export const REELS_MARGIN_TOP_DESKTOP = 100;
export const REELS_MARGIN_TOP_MOBILE = 0;

export const SYMBOL_HEIGHT_MOBILE = 55;
export const SYMBOL_WIDTH_MOBILE = 60;

export const SYMBOL_GAP = 10;
