import { Spine, TrackEntry } from "@pixi/spine-pixi";

export class SpineAnimator {
  public spine?: Spine;
  public animation?: TrackEntry;
  constructor(private skeleton: string, private atlas: string) {}

  async mount(idleAnimation: string, entryAnimation: string) {
    this.spine = Spine.from({ skeleton: this.skeleton, atlas: this.atlas, scale: 0.5 });
    this.animation = this.spine.state.setAnimation(0, entryAnimation, false);
    this.animation.listener = {
      complete: () => {
        this.spine?.state.setAnimation(0, idleAnimation, true);
      },
    };
  }
}
