import { Container, Graphics, Ticker } from "pixi.js";
import Reel from "./models/reel";
import delay from "delay";
import { Dede } from ".";
import { REELS_COUNT, SELECTION_TIME, SYMBOL_GAP, SYMBOLS_PER_REEL } from "./resources/constants";

export class ReelsManager {
  private reels: Reel[] = [];
  private destroyedReelsCount = 0;
  private fallCompleteTimeout?: NodeJS.Timeout;
  private randomNumbers: number[][] = [];

  constructor(
    private game: Dede,
    private startY: number,
    private winLimit: number,
    private events: { onDestroy: () => void; onFallComplete: (tumbleCount: number) => void },
    private reelsContainer: Container
  ) {}

  loadNumbers(incomingNumber: number[][], clearPrevious: boolean, winningSymbols?: number[]) {
    let reversed = incomingNumber;
    if (this.randomNumbers.length === 0) {
      console.log("incomingNumber", JSON.parse(JSON.stringify(this.randomNumbers)));
      this.randomNumbers = reversed;
    } else if (clearPrevious) {
      this.randomNumbers.forEach((reelNumbers, i) => {
        reelNumbers.push(...reversed[i]);
      });
    } else {
      reversed.forEach((reelNumbers, i) => {
        const newSymbols = reelNumbers.slice(
          0,
          reelNumbers.length -
            this.reels[i].symbols.filter(
              (symbol) => !winningSymbols!.includes(symbol.symbolType) && symbol.symbolType < 100000
            ).length
        );
        if (i === 0) {
          console.log("reelnumthis.randomNumbers[i]bers", this.reels[i].symbols.length);
          console.log("new reelnumbers", reelNumbers);
          console.log("new symbols", newSymbols);
        }
        this.randomNumbers[i].push(...newSymbols);
        // reelNumbers.forEach((number, k) => {
        //   if (this.randomNumbers[i][k] === undefined) {
        //     this.randomNumbers[i].push(number);
        //   }
        // });
      });
    }
  }

  processOutcome(clearPrevious: boolean, winningSymbols?: number[]) {
    if (!clearPrevious) this.game.prevOutcome = this.game.currentOutcome;
    else this.game.prevOutcome = undefined;
    this.game.currentOutcome = this.game.outcomes.shift();
    if (this.game.currentOutcome) {
      this.loadNumbers(this.game.currentOutcome.symbols, clearPrevious, winningSymbols);
    }
  }

  async mount() {
    this.game.app.stage.addChild(this.reelsContainer);

    const border = new Graphics();
    const { height, width, x, y } = this.getDynamicBounds();
    border.rect(x - SYMBOL_GAP, y - SYMBOL_GAP, width + SYMBOL_GAP * 2, height + SYMBOL_GAP * 2);
    border.stroke({ width: 2, color: 0xffffff });
    this.game.app.stage.addChild(border);

    const mask = new Graphics();
    mask.rect(x, y, width, height);
    mask.fill(0x000000);
    this.reelsContainer.mask = mask;

    this.reels = new Array(REELS_COUNT).fill(0).map((_, i) => {
      return new Reel(this.game, this.reelsContainer, i, SYMBOLS_PER_REEL, this.startY, this.randomNumbers[i], {
        onDestroy: this.handleDestroy,
        onFallComplete: this.handleFallComplete,
        onExplodeComplete: this.handleExplodeComplete,
      });
    });

    for (let i = 0; i < this.reels.length; i++) {
      this.reels[i].start();
      await delay(100);
    }
  }

  update(ticker: Ticker) {
    this.reels.forEach((el) => el.update(ticker));
  }

  async runReels() {
    await this.game.serviceManager.getSpin();

    this.processOutcome(true);
    for (let i = 0; i < this.reels.length; i++) {
      this.reels[i].destroy();
      await delay(100);
    }
  }

  private handleExplodeComplete = async () => {
    console.log("handleExplodeComplete");
    const allCompleted = this.reels.every((reel) => reel.explodedSymbols.length === reel.explodingSymbols.length);
    if (!allCompleted) return;

    this.reels.forEach((reel) => {
      reel.moveAfterExplode();
    });
  };

  private handleFallComplete = async () => {
    const allCompleted = this.reels.every((reel) => !reel.moving);
    console.log("handleFallComplete: ");
    if (!allCompleted) return;
    console.log("handleFallCompleted all");

    if (this.fallCompleteTimeout) clearTimeout(this.fallCompleteTimeout);
    this.fallCompleteTimeout = setTimeout(async () => {
      const winnings = this.game.currentOutcome?.winnings;

      if (winnings?.length) {
        this.game.tumble(this.game.currentOutcome!);

        this.reels.forEach((reel) => {
          reel.explodingSymbols = [];
          reel.explodedSymbols = [];
        });

        this.game.onSelect();
        setTimeout(() => {
          this.game.onExplode();
        }, SELECTION_TIME);

        const winningSymbols = winnings.map((winning) => winning.winningSymbol);
        winnings.forEach((winning) => {
          this.reels.forEach((reel) => {
            reel.symbols.forEach(async (symbol) => {
              if (symbol.symbolType === Number(winning.winningSymbol) || symbol.symbolType > 100000) {
                reel.explodingSymbols.push(symbol);
                symbol.select();
                symbol.shake(5, 500);
                await delay(SELECTION_TIME);
                symbol.explode();
              }
            });
          });
        });

        const diff = (this.game.currentOutcome?.runningTotal || 0) - (this.game.prevOutcome?.runningTotal || 0);

        this.events.onFallComplete(diff);
        this.processOutcome(false, winningSymbols);
        console.log("next outcome: ", this.game.currentOutcome);
      } else {
        this.events.onFallComplete(0);
      }
    }, 100);
  };

  private handleDestroy = async () => {
    this.destroyedReelsCount++;
    if (this.destroyedReelsCount === REELS_COUNT) {
      this.destroyedReelsCount = 0;
      this.reelsContainer.removeChildren();
      for (let i = 0; i < this.reels.length; i++) {
        this.reels[i].start();
        await delay(150);
      }
      this.events.onDestroy();
    }
  };

  getDynamicBounds() {
    const x = this.reelsContainer.x;
    const y = this.game.reelsEndPosition - (this.game.symbolHeight + SYMBOL_GAP) * (SYMBOLS_PER_REEL - 1);
    const width = (this.game.symbolWidth + SYMBOL_GAP) * REELS_COUNT - SYMBOL_GAP;
    const height = (this.game.symbolHeight + SYMBOL_GAP) * SYMBOLS_PER_REEL - SYMBOL_GAP;
    return { x, y, width, height };
  }
}
