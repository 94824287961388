import { Sound } from "@pixi/sound";
import { Assets } from "pixi.js";
import { Game } from "../game";

export class SoundManager {
  private backgroundMusic?: Sound;
  private explodeMusic?: Sound;
  private landingMusic?: Sound;
  private selectMusic?: Sound;
  private clickMusic?: Sound;
  loaded = false;

  constructor(private game: Game) {
    game.addSoundChangeListener((soundEnabled) => {
      if (soundEnabled) {
        this.playBackgroundMusic();
      } else {
        this.pauseBackgroundMusic();
      }
    });

    game.addSelectListener(() => {
      this.playSelectMusic();
    });

    game.addSpinListener(() => {
      this.playClickMusic();
    });
    game.addExplodeListener(() => {
      this.playExplodeMusic();
    });

    game.addClickListener(() => {
      this.playClickMusic();
    });
  }

  onAssetsLoaded() {
    this.backgroundMusic = Assets.get("backgroundMusic");
    this.explodeMusic = Assets.get("explodeMusic");
    this.landingMusic = Assets.get("landingMusic");
    this.selectMusic = Assets.get("selectMusic");
    this.clickMusic = Assets.get("clickMusic");
    this.loaded = true;
  }

  playBackgroundMusic() {
    if (!this.loaded) return;
    this.backgroundMusic!.loop = true;
    this.backgroundMusic!.play();
  }
  pauseBackgroundMusic() {
    if (!this.loaded) return;
    this.backgroundMusic!.pause();
  }

  playExplodeMusic() {
    if (!this.loaded || !this.game.soundEnabled) return;
    this.explodeMusic!.play();
  }

  playLandingMusic() {
    if (!this.loaded || !this.game.soundEnabled) return;
    this.landingMusic!.play();
  }

  playSelectMusic() {
    if (!this.loaded || !this.game.soundEnabled) return;
    this.selectMusic!.play();
  }

  playClickMusic() {
    if (!this.loaded || !this.game.soundEnabled) return;
    this.clickMusic!.play();
  }
}
