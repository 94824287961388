import { Game } from "../../lib/games/game";

export class TestHarnessClient {
  private handleMessage: (event: MessageEvent) => void;

  constructor(private game: Game) {
    // Define the handleMessage function
    this.handleMessage = this.handleIncomingMessage.bind(this);

    // Add event listener for messages
    window.addEventListener("message", this.handleMessage);
  }

  // Function to handle incoming messages
  private handleIncomingMessage(event: MessageEvent) {
    const message = event.data;

    if (typeof message === "object" && message.type) {
      switch (message.type) {
        case "testharness.spin":
          // Expose the message via the callback
          console.log("Received spin message", message);
          window.testSymbols = message.payload;
          this.game.runReels();
          break;
        default:
          console.log("Unknown message type:", message);
      }
    }
  }

  // Method to clean up and remove the event listener
  public destroy() {
    window.removeEventListener("message", this.handleMessage);
  }
}
