import { Application, Ticker, Container, Sprite, Texture } from "pixi.js";

import {
  REELS_COUNT,
  SYMBOLS_PER_REEL,
  SYMBOL_WIDTH_DESKTOP,
  SYMBOL_HEIGHT_DESKTOP,
  REELS_MARGIN_LEFT_DESKTOP,
  SYMBOL_GAP,
  REELS_MARGIN_LEFT_MOBILE,
  REELS_MARGIN_TOP_DESKTOP,
} from "./resources/constants";
import { Game } from "../game";
import { DeviceType } from "../types";
import { ReelsManager } from "./ReelsManager";
import { MainCharacter } from "./models/mainCharacter";
import { ServiceManager } from "./serviceManager";
import { IGameOutcome } from "./service/types";
import { SoundManager } from "./soundManager";
import { AssetLoader } from "../assetLoader";
import { dedeAssets } from "./resources/assets";

export class Dede extends Game {
  app: Application;
  public soundManager: SoundManager;
  public reelsManager: ReelsManager;
  private mainCharacter: MainCharacter;
  public serviceManager: ServiceManager;
  public assetLoader: AssetLoader;
  assetsLoaded = false;
  lazyAssetsLoaded = false;
  symbolWidth = 0;
  reelsEndPosition = 0;
  symbolHeight = 0;
  reelsContainer: Container;
  outcomes: IGameOutcome[] = [];
  currentOutcome?: IGameOutcome;
  prevOutcome?: IGameOutcome;

  constructor() {
    super();
    this.app = new Application();
    this.reelsContainer = new Container();
    this.serviceManager = new ServiceManager(this);
    this.soundManager = new SoundManager(this);
    this.assetLoader = new AssetLoader(dedeAssets);
    this.reelsManager = new ReelsManager(
      this,
      0,
      10,
      {
        onDestroy: () => {},
        onFallComplete: (winningAmount) => {
          if (winningAmount === 0) {
            this.isRunning = false;
          } else {
            console.log("prev balance", this.balance);
            this.balance += winningAmount;
            console.log("Winning amount: ", winningAmount, this.balance);
            this.isRunning = true;
          }
        },
      },
      this.reelsContainer
    );
    this.addDeviceTypeChangeListener(this.onResize);
    this.onResize(this.getDeviceType());
    this.isRunning = true;

    this.mainCharacter = new MainCharacter(this);
  }

  async load(onProgress: (progress: number) => void) {
    await this.assetLoader.load(
      (progress) => {
        if (progress === 1) this.assetsLoaded = true;
        onProgress(progress);
      },
      (progress) => {
        if (progress === 1) {
          this.lazyAssetsLoaded = true;
          this.soundManager.onAssetsLoaded();
        }
      }
    );
  }

  async mount() {
    await this.app.init({ background: "#39173A", resizeTo: window });
    const bg = new Sprite(Texture.from("background"));
    bg.width = window.innerWidth;
    bg.height = window.innerHeight;
    bg.scale.x = bg.scale.y = Math.max(this.app.screen.width / bg.width, this.app.screen.height / bg.height);
    this.app.stage.addChild(bg);
    await this.serviceManager.initGame();

    const gameContainer = document.getElementById("gameContainer");
    if (!gameContainer) return false;
    gameContainer.appendChild(this.app.canvas);
    window.__PIXI_APP__ = this.app;

    this.reelsManager.mount();
    this.app.ticker.add((ticker) => this.update(ticker));
    await this.mainCharacter.mount();
    this.onMountDone();
  }

  update(ticker: Ticker) {
    this.reelsManager.update(ticker);
  }

  async runReels() {
    console.log("Running reels");
    this.onSpin();
    this.isRunning = true;
    if (this.balance < this.stake) return;
    this.balance -= this.stake;
    this.reelsManager.runReels();
  }

  onResize = (deviceType: DeviceType) => {
    this.reelsContainer.x = deviceType === "desktop" ? REELS_MARGIN_LEFT_DESKTOP : REELS_MARGIN_LEFT_MOBILE;
    const newScreenWidth = window.innerWidth;
    const dynamicMobileSymbolWidth =
      (newScreenWidth - (REELS_COUNT - 1) * SYMBOL_GAP - REELS_MARGIN_LEFT_MOBILE * 2) / REELS_COUNT;

    this.symbolWidth = deviceType === "desktop" ? SYMBOL_WIDTH_DESKTOP : dynamicMobileSymbolWidth;
    this.symbolHeight = deviceType === "desktop" ? SYMBOL_HEIGHT_DESKTOP : dynamicMobileSymbolWidth;

    this.reelsEndPosition =
      deviceType === "desktop"
        ? REELS_MARGIN_TOP_DESKTOP + SYMBOL_HEIGHT_DESKTOP * SYMBOLS_PER_REEL + SYMBOL_GAP * (SYMBOLS_PER_REEL - 1)
        : window.innerHeight - 200;

    // : REELS_MARGIN_TOP_MOBILE + dynamicMobileSymbolWidth * SYMBOLS_PER_REEL + SYMBOL_GAP * (SYMBOLS_PER_REEL - 1);
  };
}
