import { ConfigProvider, theme } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Game } from "../../lib/games/game";
import styles from "./index.module.css";
import { IGameOutcome, IWinning } from "../../lib/games/dede/service/types";

const TumbleHistory: React.FC<{ game: Game }> = ({ game }) => {
  const [winnings, setWinnings] = useState<IWinning[]>([]);

  useEffect(() => {
    const listener = () => {
      console.log("spin");
      setWinnings([]);
    };
    game.addSpinListener(listener);

    const tumbleListener = (outcome: IGameOutcome) => {
      setWinnings((prev) => [...outcome.winnings, ...prev]);
    };
    game.addTumbleListener(tumbleListener);
    return () => {
      game.removeSpinListener(listener);
      game.removeTumbleListener(tumbleListener);
    };
  }, [game]);

  const renderTumble = (winning: IWinning) => {
    return (
      <>
        <img
          src={`/assets/games/dede/s${winning.winningSymbol}.png`}
          style={{ width: 16, height: 16 }}
          alt={winning.winningSymbol + ""}
        />
        <div style={{ fontSize: 16 }}> {winning.symbols.length}</div>
        <div style={{ fontSize: 16 }}> ${winning.winAmount}</div>
      </>
    );
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#D29737",
        },
      }}
    >
      {winnings.length > 0 && (
        <div className={styles.container}>
          {winnings.map((winning, index) => (
            <div key={index} className={styles.tumble}>
              {renderTumble(winning)}
            </div>
          ))}
        </div>
      )}
    </ConfigProvider>
  );
};

export default memo(TumbleHistory);
