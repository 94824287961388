import { Dede } from ".";
import { getGameInitData, getGameSpin, getGameStats } from "./service";

export class ServiceManager {
  balancePullingInterval?: NodeJS.Timeout;
  constructor(private game: Dede) {}

  async initGame() {
    const initData = await getGameInitData(this.game.id);
    this.game.config = initData.config;
    this.game.balance = initData.stats.balance;
    this.game.stake = initData.config.limits.defaultBet;
    this.game.index = initData.index;
    this.game.reelsManager.loadNumbers(initData.outcomes[0]?.symbols, true);
    console.log("Game initialized with config: ", this.game);
    clearInterval(this.balancePullingInterval!);
    this.balancePullingInterval = setInterval(() => {
      if (this.game.isRunning) return;
      this.getStats();
    }, 5000);
  }

  async getStats() {
    const stats = await getGameStats(this.game.id);
    console.log("Stats received: ", stats);
    this.game.balance = stats.stats.balance;
  }

  async getSpin() {
    const spinResult = await getGameSpin(this.game.index, this.game.stake, this.game.id);
    this.game.outcomes = spinResult.outcomes;
    this.game.index = spinResult.index;
  }
}
