import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Dede } from "./lib/games/dede";
import GameControls from "./components/gameControlsPortrait";
import GameLoader from "./components/gameLoader";
import BalanceIndicator from "./components/balanceIndicator";
import TestHarness from "./components/testHarness";
import TumbleHistory from "./components/tumbleHistory";
import { TestHarnessClient } from "./components/testHarness/testHarnessClient";
import TumbleWin from "./components/tumbleWin";

function App() {
  const [dede] = React.useState(new Dede());
  const [progress, setProgress] = React.useState(0);
  const [gameLoaded, setGameLoaded] = React.useState(false);
  const [deviceType, setDeviceType] = React.useState("desktop");
  useEffect(() => {
    dede.load((progress) => {
      console.log("progress", progress);
      setProgress(progress);
      if (progress === 1) {
        setTimeout(() => {
          dede.mount();
          setGameLoaded(true);
        }, 1000);
      }
    });

    const onResize = (deviceType: string) => {
      setDeviceType(deviceType);
    };

    dede.addDeviceTypeChangeListener(onResize);
    new TestHarnessClient(dede);

    return () => {
      dede.removeDeviceTypeChangeListener(onResize);
    };
  }, [dede]);

  console.log("deviceType", deviceType);
  return (
    <Router>
      <Routes>
        <Route path="/testharness" element={<TestHarness />} />
        {/* Define other routes here */}
        <Route
          path="/"
          element={
            <div>
              <div id="gameContainer"></div>
              {!gameLoaded && <GameLoader progress={progress} />}
              {gameLoaded && (
                <div className="controls_container">
                  <GameControls game={dede} />
                </div>
              )}
              {gameLoaded && (
                <div className={"balance_indicator_container"}>
                  <BalanceIndicator game={dede} />
                </div>
              )}
              {gameLoaded && (
                <div className={"tumble_history_container"}>
                  <TumbleHistory game={dede} />
                </div>
              )}
              {gameLoaded && (
                <div className={"tumble_win_container"}>
                  <TumbleWin game={dede} />
                </div>
              )}
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
