import { Flex } from "antd";
import React, { memo, useEffect } from "react";
import { Game } from "../../lib/games/game";
import styles from "./index.module.css";
import SlotCounter from "react-slot-counter";

const BalanceIndicator: React.FC<{ game: Game }> = ({ game }) => {
  const [balance, setBalance] = React.useState(0);
  useEffect(() => {
    const listener = (balance: number) => {
      setBalance(balance);
    };
    game.addBalanceChangeListener(listener);
    return () => {
      game.removeBalanceChangeListener(listener);
    };
  }, [game]);
  return (
    <div className={styles.container}>
      <Flex gap={8} align="center">
        <div
          style={{
            color: "white",
            fontSize: 14,
            fontWeight: "800",
          }}
        >
          BALANCE
        </div>
        <div style={{ marginTop: -2 }}>
          <SlotCounter numberSlotClassName={styles.number} value={balance} />
        </div>
      </Flex>
    </div>
  );
};

export default memo(BalanceIndicator);
