import { ConfigProvider, Flex, theme } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Game } from "../../lib/games/game";
import styles from "./index.module.css";
import { IGameOutcome } from "../../lib/games/dede/service/types";

const TumbleWin: React.FC<{ game: Game }> = ({ game }) => {
  const [outcomes, setOutcomes] = useState<IGameOutcome[]>([]);
  const [lastOutcome, setLastOutcome] = useState<IGameOutcome>();
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  useEffect(() => {
    const listener = () => {
      setOutcomes([]);
      setLastOutcome(undefined);
    };
    game.addSpinListener(listener);

    const tumbleListener = (newOutcome: IGameOutcome) => {
      setOutcomes((prev) => [...prev, newOutcome]);
      setLastOutcome(newOutcome);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setLastOutcome(undefined);
      }, 3000);
    };
    game.addTumbleListener(tumbleListener);
    return () => {
      game.removeSpinListener(listener);
      game.removeTumbleListener(tumbleListener);
    };
  }, [game]);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#D29737",
        },
      }}
    >
      {outcomes?.length > 0 && (
        <div className={styles.container}>
          <div style={{ fontSize: 12 }}>Tumble Win</div>
          {lastOutcome ? (
            <Flex justify="space-between">
              <div>
                {lastOutcome.winnings.reduce((acc, winning) => {
                  return acc + winning.winAmount;
                }, 0)}
              </div>
              {lastOutcome.multiplier > 1 && <div>x{lastOutcome.multiplier}</div>}
            </Flex>
          ) : (
            <Flex justify="space-between">
              <div>
                {outcomes.reduce(
                  (acc, outcome) =>
                    acc +
                    outcome.winnings.reduce((acc, winning) => {
                      return acc + winning.winAmount;
                    }, 0) *
                      outcome.multiplier,
                  0
                )}
              </div>
            </Flex>
          )}
        </div>
      )}
    </ConfigProvider>
  );
};

export default memo(TumbleWin);
