import { Assets } from "pixi.js";
import { sound } from "@pixi/sound";

export interface IAssetItem {
  alias: string;
  src: string;
  loadLazy?: boolean;
}
export class AssetLoader {
  initialItemsLoaded = false;
  lazyItemsLoaded = false;
  constructor(public items: IAssetItem[]) {
    if (!Assets.loader.parsers?.find((parser) => parser.name === "mp3Parser")) {
      Assets.loader.parsers.push({
        name: "mp3Parser",
        test: (url) => url.endsWith(".mp3"),
        parse: async (resource, loader) => {
          return new Promise((resolve) => {
            const soundInstance = sound.add(resource.name, resource.src);
            resolve(soundInstance);
          });
        },
      });
    }
  }

  load = async (onProgress: (progress: number) => void, onProgressLazy: (progress: number) => void) => {
    const initialItems = this.items.filter((item) => !item.loadLazy);
    const lazyItems = this.items.filter((item) => item.loadLazy);
    initialItems.forEach((item) => {
      Assets.add({ alias: item.alias, src: item.src });
    });
    await Assets.load(
      initialItems.map((item) => item.alias),
      (progress) => {
        onProgress(progress);
      }
    );

    lazyItems.forEach((item) => {
      Assets.add({ alias: item.alias, src: item.src });
    });
    await Assets.load(
      lazyItems.map((item) => item.alias),
      (progress) => {
        onProgressLazy(progress);
      }
    );
  };
}

